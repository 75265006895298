import Ranking from "../types/Ranking";
import * as config from "../config";

const baseUrl = "https://us-central1-mysper-e5462.cloudfunctions.net";

export async function getTeamsRanking(
  teamId?: string | null,
  teamIds?: string | null
): Promise<Ranking> {
  return await getRanking("teams", "averagePoints", 500, teamId, teamIds);
}

export async function getRanking(
  type: string,
  metric: string,
  maxTeams: number,
  teamId?: string | null,
  teamIds?: string | null
): Promise<Ranking> {
  let url =
    baseUrl +
    "/getRanking?competitionId=" +
    config.COMPETITION_ID +
    "&type=" +
    type;

  url += `&metric=${metric}`;
  url += `&maxTeams=${maxTeams}`;

  if (teamIds) {
    url += `&teamIds=${teamIds}`;
  } else if (teamId) {
    url += `&teamId=${teamId}`;
  }

  const requestOptions: RequestInit = {
    method: "GET",
    mode: "cors",
    redirect: "follow",
  };

  try {
    const response = await fetch(url, requestOptions);
    const json = await response.json();
    return json as Ranking;
  } catch (e) {
    console.log(e);
  }
  return {
    monthly: [],
    quarterly: [],
    highlighted: [],
  };
}
